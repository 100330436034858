import api from './api';

class ReportService {
  getReport(page, size) {

    return api.post('/api/file/list', {
        page,
        size
      });    
  }
}

const reportService = new ReportService();
export default reportService;