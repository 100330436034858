import api from './api';

class SummaryService {
  
  getReportById(reportId) {    
    const url = '/api/file/'+reportId+'/details'
    return api.get(url);    
  }
}

const summaryService = new SummaryService();
export default summaryService;