import React, { Component } from "react";
import { connect } from "react-redux";
import { Router, Switch, Route, Link } from "react-router-dom";
import styled from 'styled-components';
import "bootstrap/dist/css/bootstrap.min.css";
import { FaSignOutAlt, FaBars, FaFile, FaUserCircle } from "react-icons/fa";
import "./App.css";

import Login from "./components/login.component";
import Register from "./components/register.component";
import Home from "./components/login.component"; //as per requirement
import Profile from "./components/profile.component";
import BoardUser from "./components/board-user.component";
import BoardModerator from "./components/board-moderator.component";
import BoardAdmin from "./components/board-admin.component";
import Reports from "./components/reports.component";
import Summary from "./components/summary.component";
import FilesUpload from "./components/filesupload.component";
import Footer from "./components/footer.component"
import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";

import { history } from './helpers/history';

import EventBus from "./common/EventBus";
import Slider from "./common/Slider";

const SideNav = styled.div`
height: 100%;
background-color: #f7fafd;
border-right: 1px solid #cccfd6;
overflow-y: auto;
overflow-x: auto;
padding-top: 20px;
width: 240px;
`
const HeaderNav = styled.div`
  width: 100%;
  z-index: 1;
  padding: 0 10px;
`
const MainContainer = styled.div`
  
`
const MainWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: calc(100vh - 120px);
  margin-top:70px;
`

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    };

    history.listen((location) => {
      props.dispatch(clearMessage()); // clear message when changing location
    });
  }

  componentDidMount() {
    const user = this.props.user;

    if (user) {
      this.setState({
        currentUser: user,
       // showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
       // showAdminBoard: user.roles.includes("ROLE_ADMIN"),
      });
    }

    EventBus.on("logout", () => {
      this.logOut();
    });
  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    this.props.dispatch(logout());
    this.setState({
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    });
  }

  render() {
    const { currentUser, showModeratorBoard, showAdminBoard } = this.state;

    return (
      <Router history={history}>
        <div class="header">
          <HeaderNav className="navbar navbar-expand navbar-dark header-bg-color">
            <Link to={"/"} className="navbar-brand">
            <img src="../cropped-Broadsword-Logo-4.png"  
                     width="auto" height="100" 
                     alt="Logo" /> 
            </Link>
            <div className="aeContainer">
              <a href="https://www.alliedengineer.com/" target="_blank"><img style={{height: "60px"}} src="../images/aeLogo.png"  
                     width="auto" height="auto" 
                     alt="Logo" /> </a>
            </div>
            <div className="enpicdaContainer">
              <a href="http://www.enpicda.com/" target="_blank"><img style={{height: "40px"}} src="../images/enpICDA_online.png"  
                     width="auto" height="100" 
                     alt="Logo" /> </a>
            </div>
            {currentUser ? (
              <div className="navbar-nav ml-auto">
                <li className="nav-item">                 
                  <Link to={"/profile"} className="nav-link">
                  <FaUserCircle className="navfont"/> {currentUser.username}
                  </Link>
                </li>
              </div>
            ) : (
              <div className="navbar-nav ml-auto">
                <li className="nav-item"></li>
              </div>
            )}
          </HeaderNav>
        </div>
        <MainWrapper>
            {currentUser &&
            <div class="left">          
              <SideNav>
                <li>
                <FaBars />  <Link to="/reports"> Reports</Link>
                </li>
                <li>
                <FaFile />
                <Link to="/fileupload"> Simulate</Link>
                </li>
                <li>
                <FaSignOutAlt />
                <Link to="/login" onClick={this.logOut}> Log out</Link>
                </li>
              </SideNav>         
            </div>
            }
            <div class={(currentUser ? 'col-10 right ml250' : 'col-12 right')} >
              { currentUser == undefined &&  <Slider /> }
              <MainContainer className="container newContainer">
                <Switch>
                  <Route exact path={["/"]} component={Home} />
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/fileupload" component={FilesUpload} />
                  <Route exact path="/profile" component={Profile} />
                  <Route exact path="/reports" currentUser={currentUser} component={Reports} />
                  <Route exact path="/summary/:id" component={Summary} />
                </Switch>
              </MainContainer>
            </div>
        </MainWrapper>
        <Footer/>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(App);