import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
const AutoplaySlider = withAutoplay(AwesomeSlider);

const Slider = () => {

  return (
    <AutoplaySlider
      play={true}
      cancelOnInteraction={false} 
      interval={3000}
    >
      <div data-src="../images/Homepage-1.jpg" />
      <div data-src="../images/Homepage-2.jpg" />
      <div data-src="../images/Homepage-3.jpg" />
      <div data-src="../images/Homepage-4.jpg" />
      <div data-src="../images/Homepage-5.jpg" />
      <div data-src="../images/Homepage-6.jpg" />
      <div data-src="../images/Homepage-7.jpg" />
    </AutoplaySlider>
  )
};

export default Slider;