import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import userService from "../services/user.service";
import EventBus from "../common/EventBus";
import { FaAsterisk } from "react-icons/fa";

class Profile extends Component {

  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }

  componentDidMount() {
    userService.getUserProfile().then(
      response => {
        this.setState({
          content: response.data
        });
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });

        if (error.response && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      }
    );
  }


  render() {
    const { user: currentUser } = this.props;

    if (!currentUser) {
      return <Redirect to="/login" />;
    }


    return (
     /*  <div className="reportheader">
    <header className="summaryheader">
      <div className="row summaryd">
      <h3>Profile</h3>
      </div>
      <div className="row summarydetail">
      <div className="col-md-12 outerSummary">
      
        <p>
          <strong>User Name:</strong> {this.state.content.username}
        </p>
        <p>
          <strong>Email:</strong> {this.state.content.email}
        </p>
        <p>
          <strong>Mobile:</strong> {this.state.content.mobileNumber}
        </p>
        </div>
     
       </div> 
      </header>
    </div> */
    <div className="reportheader" style={{width: "50%", margin: "0 auto"}}>
    <header className="summaryheader">
      <div className="row summaryd">
      <h3>Profile</h3>
      </div>
      <div className="row summarydetail">
      <div className="col-md-12 outerSummary">
        <div className="col-md-6 leftc">
        <label>Username</label><p>{this.state.content.username}</p>
        </div>
        <div className="col-md-6 rightc">
        <div style={{width: "50%", float: "left"}}><label>Mobile</label><p>{this.state.content.mobileNumber}</p> </div>
        </div>
        <div className="col-md-6 leftc">
        <label>Email</label><p>{this.state.content.email}</p>
        </div>
        <div className="col-md-6 rightc">
        <label>Password</label><p style={{fontSize: "12px"}}><FaAsterisk>{this.state.content.password}</FaAsterisk><FaAsterisk/><FaAsterisk/><FaAsterisk/><FaAsterisk/></p>
        
        </div>
        
      </div>
       </div> 
      </header>
    </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(Profile);
