import api from "./api";
import tokenService from "./token.service";

class AuthService {
    login(basicToken) {
      const credentials = atob(basicToken);
      const username = credentials.split(":")[0];
      const password = credentials.split(":")[1];

      return api
        .post("/auth/login", {
          username,
          password
        })
        .then(response => {
          if (response.data.token) {
            tokenService.setUser({
              accessToken: response.data.token,
              username: username,
              expiresIn: response.data.expiresIn
            });
          }
          return response.data;
        });
  }

  logout() {
    tokenService.removeUser();
  }

  register(username, email, password) {
    return api.post("/auth/signup", {
      username,
      email,
      password
    });
  }
}

const authService = new AuthService();
export default authService;
