import React, { useEffect, useState } from "react";
import summaryService from "../services/summary.service";
import { FaFileDownload } from "react-icons/fa";
import { Link } from "react-router-dom";
import FileUploadService from "../services/fileupload.service";

const Summary = (p) => {

  const [data, setData] = useState([]);

  useEffect(() => {
    
    summaryService.getReportById(p.match.params.id).then(
      response => {
        const data = response.data.data;
        setData(data);
      },
      error => {
        setData((error.response && error.response.data) ||
          error.message ||
          error.toString());
      }
    );
  }, []);

  function ListItem(props) {
    // Correct! There is no need to specify the key here:  
    let imgName = "";
    
    if(props.value === "Single_Gas") {
      imgName = "../images/flowregimes/SingleGas.jpg";
    } else if(props.value === "Stratified-Smooth") {
      imgName = "../images/flowregimes/StratifiedSmooth.gif";
    } else if(props.value === "Elongated-Bubble") {
      imgName = "../images/flowregimes/ElongatedBubble.jpg"; //
    } else if(props.value === "Annular-Mist") {
      imgName = "../images/flowregimes/AnnularMist.gif";
    } else if(props.value === "Bubble") {
      imgName = "../images/flowregimes/Bubble.gif";
    } else if(props.value === "Churn") {
      imgName = "../images/flowregimes/Churn.jpg";
    } else if(props.value === "Dispersed-Bubble") {
      imgName = "../images/flowregimes/DispersedBubble.gif";
    } else if(props.value === "Froth") {
      imgName = "../images/flowregimes/Froth.jpg";
    } else if(props.value === "Single_Liquid") {
      imgName = "../images/flowregimes/SingleLiquid.jpg";
    } else if(props.value === "Slug") {
      imgName = "../images/flowregimes/Slug.gif";
    } else if(props.value === "Stratified-Wavy") {
      imgName = "../images/flowregimes/StratifiedWavy.gif";
    }

    return <li className="flowList">{props.value} <br/> {imgName && <img src={imgName}  width="200px" height="140px" />}</li>;
  }

  const downloadFile = (fileId, lineId) => {
   
    FileUploadService.getFile(fileId).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', lineId+'.xlsx');
          document.body.appendChild(link);
          link.click();
    });
  }

  return (
    <div className="reportheader">
    <header className="summaryheader">
      <div className="row summaryd">
      <h3>Summary</h3>
      </div>
      <div className="row summarydetail">
      <div className="col-md-12 outerSummary">
        <div style={{textAlign: "right", paddingRight: "20px"}} onClick={() => downloadFile(data.id, data.lineId)}>
          <FaFileDownload /><Link className="downloadReports" to="#"> Download</Link>
        </div>
        <div className="col-md-4 leftc">
          <label>File Id</label><p>{data.id?data.id: '-'}</p>
        </div>
        <div className="col-md-4 leftc">
          <label>Line Id</label><p>{data.lineId?data.lineId: '-'}</p>
        </div>
        <div className="col-md-4 leftc">
          <label>Timestamp</label><p>{data.processedTime?data.processedTime: '-'}</p>
        </div>
        <div className="col-md-4 rightc">
          <div style={{width: "50%", float: "left"}}><label>pH</label><p>{data.avgPH?data.avgPH: '-'}</p> </div>
        </div>
        <div className="col-md-4 centerc">
          <label>SGV(m/s)</label><p>{data.avgSGV?data.avgSGV: '-'}</p>
        </div>
        <div className="col-md-4 centerc">
          <label>SLV(m/s)</label><p>{data.avgSLV?data.avgSLV: '-'}</p>
        </div>
        <div className="col-md-4 centerc">
          <label>Corrosion Rate(mpy)</label><p>{data.corrosionRate?data.corrosionRate: '-'}</p>
        </div>
        <div className="col-md-4 rightc">
          <label>Annual Wall Loss(%)</label><p>{data.annualWallLoss?data.annualWallLoss: '-'}</p>
        </div>
        <div className="col-md-12 leftc">
          <label>Flow Pattern</label><p><ul style={{padding: "0px"}}>{data.flowPattern?data.flowPattern.map(name => (
            <ListItem key="" value={name} />
          )): ''}</ul></p>
        </div>        
      </div>
       </div> 
      </header>
    </div>
  );
}

export default Summary;