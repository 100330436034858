import api from './api';

const upload = (formData) => {
  return api.post("/api/file/upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    timeout: 100000,
  });
};

const getFile = (fileId) => {
  return api.get(`/api/file/${fileId}/download`, {responseType: 'blob'});
};

const downloadTemplates = () => {
  return api.get(`/api/file/download/simulation/templates`, {responseType: 'blob'});
};

const FileUploadService = {
  upload,
  getFile,
  downloadTemplates,
};

export default FileUploadService; 