import React, { useState, useRef } from "react";
import UploadService from "../services/fileupload.service";
import Loading from "../common/Loading";
import { FaFileDownload } from "react-icons/fa";
import { Link } from "react-router-dom";

const UploadFiles = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [message, setMessage] = useState();
  const [fileUploadStatus, setFileuploadStatus] = useState("failure");
  const [isLoading, setIsLoading] = useState(false);
  const fileref1 = useRef();
  const fileref2 = useRef();

  const selectFiles =   (event, index) => {
    setMessage('')
    let files = selectedFiles;
    
    if(selectedFiles[index]) {
      files.slice(index, 0, event.target.files);
    } else {
      files.push(event.target.files);
    }
    setSelectedFiles(files);
  };

  const downloadTemplates = () => {
   
    UploadService.downloadTemplates().then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'SimulationTemplates.zip');
          document.body.appendChild(link);
          link.click();
    });
  };

  const uploadFiles = () => {
    setIsLoading(true);
    let formData = new FormData();
    const files = Array.from(selectedFiles);   
    files.forEach((file) => formData.append("files", file[0]));
    
    UploadService.upload(formData).then((response) => {
      setMessage(response.data.message);
      setFileuploadStatus("success");
      fileref1.current.value = "";
      fileref2.current.value = "";
      setIsLoading(false);
      setSelectedFiles([]);
    })
    .catch((error) => {
      
      if (error.code === 'ECONNABORTED') {
        console.error('A timeout has occurred.');
        setMessage('Simulation for this scenario is taking longer than expected. Please check the reports page after some time to see the results.');
        setFileuploadStatus("success");
      }else{
        setMessage(error.response.data.message);
        setFileuploadStatus("failure");
      }
      fileref1.current.value = "";
      fileref2.current.value = "";
      setIsLoading(false);
      setSelectedFiles([]);
    });
  };

  return (
    <div>
      <div className="reportheader">
        <header className="summaryheader">
          <div className="row summaryd">
            <h3>Simulate</h3>
          </div>
          <div className="row summarydetail">
            <div className="col-md-12 outerSummary">
            <div style={{textAlign: "right", paddingRight: "20px"}} onClick={() => downloadTemplates()}>
              <FaFileDownload /><Link className="downloadReports" to="#"> Templates</Link>
            </div>
              
            {isLoading && <Loading/>}

            <div className="row my-3" style={{margin: "20px"}}>
              <div className="col-8">
                <label className="btn btn-default p-0">
                  <span className="fileUploadField">Input Data File</span> <input type="file" ref={fileref1} onChange={(e)=> selectFiles(e, 0)} />
                </label>
              </div>

              <div className="col-8">
                <label className="btn btn-default p-0">
                  <span className="fileUploadField" style={{paddingRight: "34px"}}>Elevation File</span> <input type="file" ref={fileref2} onChange={(e)=> selectFiles(e, 1)} />
                </label>
              </div>

              <div className="col-8" style={{marginTop: "30px", marginLeft: "166px"}}>
                <button
                  className="btn btn-sm simulateBtn"
                  disabled={selectedFiles.length === 0}
                  onClick={uploadFiles}
                >
                  Upload
                </button>
              </div>
            </div>

            {message && (
              <div className={(fileUploadStatus == "success" ? 'alert alert-success' : 'alert alert-danger')} role="alert">
                {message}
              </div>
            )}
            </div>
          </div> 
        </header>
      </div>
    </div>
  );
};

export default UploadFiles;