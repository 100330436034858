import React from 'react';

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <div className="col-12 ">
        <div className="row footertop">
            <div className="footerleft">
                Developed By : PerkSoft
            </div>
            <div className="footercenter">
                © {currentYear} Copyright:Broadsword Corrosion Engineering
            </div>
            <div className="footerright">
                <img src="../trademark.png"  
                    width="auto" height="110" 
                    alt="Logo" /> 
            </div>
        </div>
    </div>
  );
}

export default Footer;