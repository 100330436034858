import React, { useEffect, useState, useMemo } from "react";

import reportService from "../services/report.service";
import RTable from "../common/components/rb-table/RTable";
import styled from "styled-components";

const ErrorMessage = styled.div`
  display: flex;
  justify-content: center;
  color: 
`

const Reports = () => {

  //let curuser = props.cu;

  const [data, setData] = useState([]);
  const [message, setMessage] = useState(null);

  //File Id, Timestamp, Corrosion Rate(mpy), SGV(m/s), pH, Flow Pattern, Annual Wall Loss(%),  Actions
  const columns = useMemo(
    () => [
      {
        Header: "File Id",
        accessor: "id"
      },
      {
        Header: "Line Id",
        accessor: "lineId"
      },
      {
        Header: "Timestamp",
        accessor: "processedTime"
      },
      {
        Header: "Corrosion Rate(mpy)",
        accessor: "corrosionRate"
      },
      {
        Header: "SGV(m/s)",
        accessor: "avgSGV"
      },
      {
        Header: "SLV(m/s)",
        accessor: "avgSLV"
      },
      {
        Header: "pH",
        accessor: "avgPH"
      },
      {
        Header: "Flow Pattern",
        accessor: "flowPattern"
      },
      {
        Header: "Annual Wall Loss(%)",
        accessor: "annualWallLoss"
      },
      {
        Header: "Actions",
        accessor: "link"
      }
    ],
    []
  );

  useEffect(() => {
    setMessage(null)
    reportService.getReport(0, 100).then(
      response => {
        const data = response.data.data.enpicdaFileDataList.map(item => {  
          return {
            ...item,
            flowPattern: item.flowPattern.join(","),
            link: {
              to: '/summary/' + item.id,
              name: 'View'
            }
          }
        })
        setData(data);
      },
      error => {
        setMessage((error.response && error.response.data) ||
          error.message ||
          "No Data!");
      }
    );
  }, []);

  return (
    <div className="reportheader">
      <header className="jumbotron">
        <h3>Reports</h3>
     
        {message ? 
          <ErrorMessage>{message}</ErrorMessage>
          :
          <RTable columns={columns} data={data} />
        }
       </header>
    </div>
  );
}

export default Reports;